<template lang="pug">
	div(class="imprint")
		div(class="row")
			div(class="col s12")
				separator(text="Impressum")
		div(class="row")
			div(class="col s12")
				div(class="contentbox")
					p
						| Angaben nach §5 TMG:<br/>
						| <br/>
						| <b>DGBRT GmbH</b><br/>
						| Alter Schlachthof 3<br/>
						| 76131 Karlsruhe<br/>
						| <br/>
						| <b>Mail:</b> support (ät) dgbrt (punkt) de<br/>
						| <br/>
						| <b>Sitz:</b> Karlsruhe<br/>
						| Amtsgericht Mannheim<br/>
						| <b>HRB:</b> 727527<br/>
						| <b>Geschäftsführer:</b> D. Benjamin Stieler<br/>
</template>

<script>
import Separator from '@/components/Separator';
export default {
	components: {
		'separator': Separator
	}
}
</script>